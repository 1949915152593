export const environment = {
  production: false,
  pr1ApiUrl: 'https://pr1stdqa.przone.net/pr1core/Private',
  appKey: 'promotion engine',
  environment: 'qa',
  appMode: 'standalone',
  commentApiUrl:'https://pr1stdqa.przone.net/pr1commentapi/Private',
  shopifyAuth: {
    apiKey: '',
    host: '',
  },
  applicationInsights: {
    instrumentationKey: 'eb3443ff-748f-4380-8b70-f7eaafc5ea99', // qa environment
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
  knockApp: {
    FEED_KEY: 'b8a11870-97b0-47a5-a95c-9dee1131200e',
    CLIENT_KEY: 'pk_MJOElQTiKC46LYx1htZMBF_iMpN5c6Qn4ZjbzZWA3Iw',
  },
  auth: {
    domain: 'pr1-test.us.auth0.com',
    clientId: '3piKdUctouNlsqnukXcj8L4p2XptPPAT',
    audience: 'https://pr1-std-private-api.przone.net',
    organization: 'org_GOY5hYYiRctXRQC6',
    redirectUri: window.location.origin + '/pr1core/',
  },
  httpInterceptor: {
    allowedList: [`https://pr1stdqa.przone.net/pr1core/Private/*`,`https://pr1stdqa.przone.net/pr1commentapi/Private/*`],
  },
  blobStorage: {
    kroger: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    riteaid: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    standard: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    walgreens: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    demo: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    qaautomation: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    unfi: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
    arg: {
      assetsUrl:
        'https://pr1stdqastandardblob.blob.core.windows.net/public/assets',
    },
  },
};
